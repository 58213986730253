@import '../styles.scss';

.card {
  background: $points-card;
  padding-bottom: 16px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .header {
    width: 100%;
    border-radius: 10px 10px 0 0;
    background-color: rgba(0, 0, 0, 0.123);
    display: flex;
    align-items: center;
    overflow-x: hidden;

    .emoji {
      background-color: rgba(0, 0, 0, 0.123);
      border-radius: 10px 0 10px 0;
      font-size: 36pt;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      cursor: pointer;
    }

    .name {
      flex: 1;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16pt;
    }

    input {
      width: 90%;
      height: 40px;
      font-size: 16pt;
      text-align: center;
      background-color: #00000048;
      border: 1px $overlay-border;
      border-radius: 5px;
      color: white;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .points {
      margin: 0;
      color: $font-green;
      font-size: 16pt;
      cursor: pointer;
    }

    input {
      @extend .points;
      width: 50px;
      height: 30px;
      font-size: 16pt;
      text-align: center;
      background-color: #00000048;
      border: 1px $overlay-border;
      border-radius: 5px;
    }
  }

  .button {
    margin: 0 5px;
    padding: 5px 10px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:disabled {
      color: #898989bf;
      cursor: default;
      background-color: rgb(42, 42, 42);

      &:hover {
        background-color: rgb(42, 42, 42);
      }
    }
  }

  .move {
    @extend .button;
    background-color: rgba(138, 0, 0, 0);
    color: #ffffff62;
    max-height: 40px;

    &:disabled {
      color: #0000006b;
      cursor: default;
      background-color: rgba(138, 0, 0, 0);

      &:hover {
        background-color: rgba(138, 0, 0, 0);
      }
    }

    &:hover {
      background-color: rgba(0, 195, 255, 0.479);
    }
  }

  .buttonGroup {
    margin-top: 10px;

    .down {
      @extend .button;
      background-color: rgb(138, 0, 0);

      &:hover {
        background-color: rgb(104, 0, 0);
      }
    }

    .up {
      @extend .button;
      background-color: rgb(0, 103, 14);

      &:hover {
        background-color: rgb(0, 75, 10);
      }
    }

    .redeem {
      @extend .up;

      background-color: rgb(15, 109, 43);
    }
  }
}