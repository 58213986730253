$font-green:rgb(145, 207, 144);

$overlay-black: rgba(7, 7, 7, 0.945);
$overlay-border: #2c2c2c;
$points-card: rgba(62, 63, 63, 0.808);

$input-background: #2e2e2e;
$input-color: #ffffff;

$buttons: rgba(54, 54, 54, 0.435);
$buttons-hover: rgba(43, 43, 43, 0.418);

$font-error: rgb(247, 120, 120);