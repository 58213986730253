.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90%;
    color: rgb(255, 182, 182);

    h1 {
        font-size: 60pt;
        margin: 0;
        padding: 0;
    }

    h2 {
        font-size: 40pt;
        margin: 0;
        padding: 0;
    }
}