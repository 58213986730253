@import '../styles.scss';

.emojiPicker {
  position: fixed;
  width: 100%;
  width: 420px;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid $overlay-border;
  border-radius: 5px;
  background-color: $overlay-black;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 99;
}

.searchBar {
  width: 100%;
  padding: 8px;
  border: 1px solid $overlay-border;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: $input-background;
  color: $input-color;
}

.emojiGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 40px);
  grid-template-rows: repeat(auto-fill, 50px);
  gap: 5px;
  padding: 10px;
  max-height: 320px;
  width: calc(100% - padding * 2);
  overflow-y: auto;
  overflow-x: hidden;
}

.emojiItem {
  font-size: 38px;
  text-align: center;
  cursor: pointer;
  padding: 0;
  margin: 0;

  &:hover {
    background-color: $buttons-hover;
  }
}