.pointsListContainer {
  padding: 10px;
  padding-top: 0;

  .newPointButton {
    button {
      padding: 5px 10px;
      background-color: #0030649c;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 20pt;
      height: 50px;
      min-width: 50px;

      &:hover {
        background-color: #001933c5;
      }
    }
  }

  .cancelDelete {
    @extend .newPointButton;
    
    font-size: 12pt;
  }

  .deleteButton {
    @extend .newPointButton;

    button {
      background-color: #e900469c;

      &:hover {
        background-color: #7c00259c;
      }
    }
  }

  .buttonRow {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 20px;
    margin-bottom: 20px;
  }

  .pointsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 16px;
  }
}